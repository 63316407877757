import React from "react";

const ContactForm = () => {
    return (
        <form className="page-body" action="mailto:kniffeng@yahoo.com" method="get" enctype="text/plain">
            <div className="form-group">
                <label htmlFor="name">Name:</label>
                <input type="text" name="name" id="name" className="form-control" />
            </div>
            <div className="form-group">
                <label htmlFor="email">Email: </label>
                <input type="text" name="email" id="email" className="form-control"/>
            </div>
            <div className="form-group">
                <label htmlFor="comments">Comments:</label>
                <br />
                <textarea name="comments" id="comments" rows="12" cols="35"  className="form-control">Send your comments to us.</textarea>
            </div>
            <div>
                <input type="submit" name="submit" value="Send" className="btn btn-primary" />
            </div>
        </form>
    )
}

export default ContactForm

