import React from "react";
import ContentPage from "../components/content-page";
import ContactForm from "../components/contact-form";
import SEO from "../components/seo";

const ContactPage = () => (
    <ContentPage title="Contact Form">
        <SEO title="Contact" />
        <ContactForm></ContactForm>
    </ContentPage>
)

export default ContactPage
